import React from 'react';


const UnknownIcon = ({
                         className,
                         fontSize
                     }) => {
    return (
        <svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
             viewBox="0 0 646.000000 1280.000000">
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M3049 12790 c-25 -4 -70 -22 -100 -38 -30 -17 -85 -42 -124 -57 -157
-59 -308 -172 -385 -290 -47 -72 -94 -194 -117 -300 -8 -38 -23 -101 -34 -140
-10 -38 -19 -80 -19 -92 0 -16 -6 -23 -19 -23 -10 0 -24 -7 -31 -15 -7 -8 -18
-15 -24 -15 -7 0 -31 -14 -54 -31 -34 -25 -42 -37 -42 -63 0 -25 -10 -42 -46
-76 -67 -66 -132 -160 -144 -211 -5 -24 -25 -120 -42 -214 -28 -144 -32 -192
-32 -310 1 -156 21 -394 34 -410 4 -5 13 -35 18 -65 12 -63 42 -145 78 -209
13 -24 22 -47 19 -51 -2 -4 -62 -33 -132 -65 -211 -95 -304 -154 -503 -320
-183 -153 -274 -249 -352 -374 -84 -133 -123 -240 -169 -459 -26 -126 -31
-181 -39 -387 -5 -132 -19 -321 -30 -420 -12 -99 -26 -232 -33 -295 -13 -141
-12 -710 3 -1140 l11 -315 -29 -66 c-28 -62 -32 -67 -89 -91 -33 -15 -76 -39
-94 -55 -19 -17 -62 -48 -95 -71 -62 -42 -153 -134 -197 -200 -13 -21 -40 -62
-60 -92 -21 -30 -37 -62 -37 -71 0 -9 -11 -42 -25 -74 -147 -332 -152 -834
-10 -1120 81 -164 246 -354 405 -467 67 -47 258 -148 282 -148 41 -1 41 -14 0
-161 -54 -192 -72 -283 -82 -414 -18 -237 32 -900 91 -1210 12 -66 24 -188 30
-305 5 -107 14 -242 21 -300 14 -116 8 -234 -18 -364 -12 -62 -14 -107 -9
-217 l8 -138 -32 -66 c-46 -95 -56 -184 -45 -392 11 -194 16 -209 92 -251 23
-13 42 -30 42 -37 0 -23 58 -63 107 -73 99 -21 192 -23 378 -8 104 9 216 16
248 16 36 0 103 13 180 35 155 45 155 45 232 107 l65 52 0 61 c0 59 -3 67 -75
186 -96 159 -132 201 -217 256 -46 30 -74 56 -87 82 -11 20 -54 87 -95 149
-69 101 -86 132 -71 132 24 0 205 -108 248 -148 l27 -26 27 20 c65 48 47 113
-57 216 -61 60 -97 86 -157 113 -42 20 -89 39 -104 42 -51 10 -61 80 -35 240
8 49 22 133 31 188 14 90 35 176 119 500 14 55 39 157 55 227 17 69 64 216
104 325 41 109 79 230 85 268 19 118 41 323 57 521 20 257 42 406 72 489 13
39 28 96 34 128 9 60 28 105 52 125 25 21 313 36 440 23 140 -15 366 -51 562
-90 179 -36 312 -47 535 -45 l165 1 266 71 c147 40 297 86 335 103 38 17 98
42 134 55 36 14 95 43 132 65 36 22 88 49 115 59 51 19 182 36 206 27 16 -6
-17 -144 -97 -401 -25 -82 -46 -161 -46 -176 0 -14 -6 -49 -14 -76 -19 -64
-24 -99 -40 -277 -15 -157 -11 -283 19 -542 18 -153 19 -394 4 -740 -6 -137
-12 -369 -13 -515 -3 -248 -10 -322 -33 -339 -18 -13 -108 -250 -139 -361 -8
-30 -19 -64 -24 -75 -16 -38 -35 -181 -35 -271 1 -101 15 -136 63 -156 15 -6
124 -14 243 -19 131 -5 307 -20 450 -39 344 -44 674 -39 906 15 179 42 260
182 178 307 -25 38 -135 135 -177 156 -13 6 -59 25 -103 41 -79 30 -130 61
-290 173 -102 73 -147 109 -141 116 3 2 33 -12 66 -32 73 -43 107 -47 116 -12
12 48 0 70 -61 116 -33 25 -89 69 -123 98 -75 62 -126 90 -178 96 -44 6 -51
15 -59 76 -5 37 -2 53 19 91 14 25 35 88 45 140 11 52 31 142 45 200 14 59 46
205 70 325 67 327 83 393 130 548 114 368 143 500 211 973 36 246 65 475 65
508 0 33 7 105 16 160 33 217 35 407 3 595 -27 166 -167 348 -328 429 -46 22
-92 41 -102 41 -10 0 -20 4 -23 9 -11 18 -396 142 -596 192 -149 37 -379 58
-860 78 -256 11 -492 21 -524 23 -59 3 -60 4 -69 37 -4 18 -18 46 -29 62 -20
26 -21 37 -15 156 8 158 36 334 71 433 15 41 61 165 104 275 l78 200 174 11
c96 6 177 10 180 7 9 -9 19 -146 20 -288 0 -179 16 -261 66 -359 20 -39 45
-95 55 -125 29 -81 214 -291 276 -312 49 -16 108 3 197 61 155 102 178 140
257 425 88 316 89 351 17 737 l-21 117 22 78 c13 43 27 116 32 163 5 47 15
112 24 145 8 33 17 101 21 150 3 50 12 109 20 133 10 31 12 71 8 145 -6 110
-23 174 -54 202 -11 10 -20 27 -20 37 0 46 -52 108 -105 125 l-30 10 -3 154
c-2 99 3 208 14 304 34 304 -18 539 -168 772 -57 87 -166 212 -218 248 -52 36
-136 41 -450 26 -439 -22 -474 -23 -490 -12 -13 10 -13 12 0 21 8 5 32 10 53
10 75 0 129 51 172 160 13 32 39 85 58 117 40 64 97 203 97 233 0 21 79 302
130 464 46 146 42 251 -21 543 -16 76 -38 192 -50 258 -26 152 -62 289 -101
385 -28 67 -43 86 -136 177 -57 57 -128 117 -158 134 -29 16 -56 34 -59 39
-16 25 -104 65 -150 67 -223 9 -357 11 -396 3z"/>
            </g>
        </svg>

    )
}
export default UnknownIcon;