import React from 'react';


const TeamIcon = ({
                            className,
                            fontSize
                        }) => {
    return (
        <svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
             viewBox="0 0 511.999 511.999">
            <g>
                <g>
                    <path d="M364.917,374.977l-18.376-17.894c-13.436,5.551-29.424,1.533-38.492-10.667c-6.877-9.25-8.095-20.952-4.282-30.983
			l-33.107-32.236c-7.547-7.349-19.218-8.39-27.947-2.494l-55.298,37.353l-23.495-90.658l50.16-75.24
			c3.288-4.932,3.709-11.238,1.107-16.564c-2.601-5.325-7.835-8.869-13.747-9.307l-66.788-4.947
			c6.406,6.782,10.969,15.211,13.077,24.371c0.774,3.364,1.212,6.753,1.327,10.126l21.441,1.588l-29.629,44.443L79.773,217.4
			l21.785,19.402l-59.234-33.965l11.844-13.876c-4.319-5.811-7.445-12.493-9.085-19.618c-1.559-6.771-1.764-13.641-0.68-20.297
			L3.99,196.4c-3.211,3.761-4.584,8.755-3.752,13.629s3.788,9.128,8.065,11.611l78.38,45.49l-16.537-3.997l25.961,111.895
			c2.64,11.381,10.711,20.734,21.583,25.012c10.872,4.279,23.152,2.934,32.841-3.595l102.074-68.782l81.298,79.162
			c8.797,8.565,22.871,8.375,31.432-0.417C373.899,397.613,373.712,383.541,364.917,374.977z"/>
                </g>
            </g>
            <g>
                <g>
                    <circle cx="96.409" cy="157.528" r="38.575"/>
                </g>
            </g>
            <g>
                <g>
                    <circle cx="385.972" cy="139.583" r="40.711"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M510.761,307.093c-3.264-12.484-4.826-18.202-28.48-104.096c-4.559-16.559-21.766-26.22-38.271-21.499l-27.318,7.811
			c-16.497,4.717-25.989,21.994-21.131,38.444l2.036,6.895l16.477-17.368l-28.024,48.171l-62.908,46.764
			c-8.175,6.078-9.875,17.631-3.799,25.807c6.076,8.175,17.631,9.876,25.807,3.799l65.944-49.022c2.004-1.49,3.684-3.37,4.939-5.527
			l29.929-51.445l-17.765,58.522c-1.517,4.996-4.733,6.792-8.707,9.745l-50.315,37.403c2.252,2.627-3.793-2.215,87.974,67.07
			c9.757,7.367,23.636,5.427,31.002-4.327c7.367-9.756,5.428-23.635-4.327-31.002l-40.839-30.834l49.568-6.077
			C506.474,334.62,515.405,320.501,510.761,307.093z"/>
                </g>
            </g>
        </svg>)
}
export default TeamIcon;