import './dayContainer.css';
import React, { useState} from "react";
import {getHourParts, getPossibleHours} from "../../util/hoursUtil";
import DisplayClass from "../displayClass/DisplayClass";
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {removeClassesToBook, saveClassesToBook} from "../../service/classesToBookService";

const HourDelimiter = ({hour, classes, day, onUpdate}) => {
    const [editMode, setEditMode] = useState(false);
    const [selectdClass, setSelectedClass] = useState(null);
    const [loading, setLoading] = useState(false);

    const toggleEdit = () => {
        setEditMode(!editMode);
    }

    const onSelectClass = (classInfo) => {
        setSelectedClass(classInfo);
    }

    const onSave = async () => {
        setLoading(true)
        const classToBook = {
            name: selectdClass.name,
            time: hour,
            day: day,
            duration: 1
        }
        await saveClassesToBook(classToBook);
        setLoading(false);
        onUpdate("CREATE", classToBook);
        setEditMode(false);
    }

    const footerContent = (
        <div className="p-fluid">
            <Button label="Cancel" icon="pi pi-times" iconPos="left" onClick={() => setEditMode(false)}
                    className="p-button-text"/>
            <Button loading={loading} disabled={!selectdClass} label="Save" icon="pi pi-check" iconPos="left"
                    onClick={onSave} autoFocus/>
        </div>
    );
    return (
        <>
            <div onClick={toggleEdit} className="hour_delimiter"/>
            <Dialog header={`Select a class to schedule booking at ${hour}`} visible={editMode}
                    onHide={() => setEditMode(false)} footer={footerContent}>
                <div className="select_class_container">
                    {classes.map((classInfo) =>
                        <div className={`select_class ${selectdClass === classInfo ? "selected_class" : ""}`}>
                            <DisplayClass element={classInfo} onElementClick={() => {
                                onSelectClass(classInfo)
                            }}/>
                        </div>
                    )}
                    {classes.length === 0 && <div className="no_class">No classes available</div>}
                </div>
            </Dialog>
        </>
    )
}
const DayContainer = ({day, classes, currentClasses, onUpdate}) => {
    const hours = getPossibleHours();
    const toStandardTime = (time) => {
        const [hour, min, amPm] = getHourParts(time);
        if (min === 0) {
            return `${hour} ${amPm.toUpperCase()}`;
        }
        return `${hour}:${min}  ${amPm.toUpperCase()}`;
    }
    const findClasses = (hour) => {
        return classes.filter(classInfo =>
            classInfo.hours.some(classHour => classHour === toStandardTime(hour))
        );
    }
    const getHours = () => {
        return hours.map(hour => (
            <HourDelimiter hour={hour} classes={findClasses(hour)} day={day} onUpdate={onUpdate}/>))
    }
    const getTopPosition = (time) => {
        const [hour, min, amPm] = getHourParts(time);
        return ((hour - 6) * 60 + min) + 32 + ((amPm === "pm" && hour !== 12) ? 720 : 0)
    }

    const onElementClick = async (classInfo) => {
        classInfo.loading = true;
        await removeClassesToBook(classInfo.id);
        onUpdate("DELETE", classInfo);
        classInfo.loading = false;
    }

    return (<div className="day_container">
        <span>{day}</span>
        {getHours()}
        {currentClasses.map(classInfo => (
            <div className="class_container_day"
                 style={{top: getTopPosition(classInfo.time), height: (classInfo.duration || 1) * 60}}>
                <DisplayClass element={classInfo} onElementClick={onElementClick} onHoverText={"Click to remove"} loading={classInfo.loading}/>
            </div>
        ))}
    </div>);
}

export default DayContainer;