import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBB_2ZPKoFRSc6ly-HxIkI-r1GTXCyb-so",
    authDomain: "crossfit-scheduler-60b19.firebaseapp.com",
    projectId: "crossfit-scheduler-60b19",
    storageBucket: "crossfit-scheduler-60b19.appspot.com",
    messagingSenderId: "816862782545",
    appId: "1:816862782545:web:6806d001845f9700384470",
    measurementId: "G-EGD2PJMEWZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {analytics}
