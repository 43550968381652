import {get as getConfig} from "../util/config";
import {get, post, remove} from "../util/restClient";

export async function getClassesToBook() {
    const url = getConfig('GET_CLASSES_TO_BOOK_URL');
    return await get(url);
}

export async function saveClassesToBook(classToBook) {
    const url = getConfig('CREATE_CLASSES_TO_BOOK_URL');
    return await post(url, classToBook);
}

export async function removeClassesToBook(id) {
    const url = getConfig('REMOVE_CLASSES_TO_BOOK_URL', {id});
    return await remove(url, {id});
}