import React, {useEffect, useState} from 'react';
import './weekContainer.css';
import {getClassesByWeekDay} from "../../service/classesService";
import {getPossibleHours} from "../../util/hoursUtil";
import DayContainer from "./dayContainer";
import {getClassesToBook} from "../../service/classesToBookService";

const WeekContainer = () => {
    const hours = getPossibleHours();
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const [classes, setClasses] = useState([]);
    const [classesToBook, setClassesToBook] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        getClassesByWeekDay().then(data => setClasses(data.data||[]));
        getClassesToBook().then(data => setClassesToBook(data.data||[]));
        setLoading(false);
    }, []);

    const getCurrentClasses = (day) => {
        return classesToBook.filter(classInfo => classInfo.day === day);
    }

    const processTime = (time) => {
        const regexResult = /(.*)(am|pm)/.exec(time);
        return [regexResult[1], regexResult[2]];
    }

    const onUpdate = (action, entity) => {
        switch (action) {
            case "CREATE":
                setClassesToBook([...classesToBook, entity]);
                break;
            case "DELETE":
                setClassesToBook(classesToBook.filter(classInfo => classInfo.id !== entity.id));
                break;
            default:
                break;
        }
    }


    return (
        <div className="week_container">
            {loading && <div className="loading_container">Loading...</div>}
            <div className="hours_container">
                {hours.map((hour, i) => (i % 2 === 0 && <div className="hour">
                    <span>{processTime(hour)[0]}</span>
                    <span>{processTime(hour)[1]}</span>
                </div>))}
            </div>
            {days.map(day => (
                <DayContainer day={day} classes={classes[day] || []} currentClasses={getCurrentClasses(day)}
                              onUpdate={onUpdate}/>))}
        </div>
    )
}

export default React.memo(WeekContainer)