import {createContext, useContext} from "react";


export const user = {
    name: '',
    email: '',
    image: '',
    token: '',
    isAuthenticated: () => {
        return !!user.token && !!user.email && !!user.name;
    },
    setToken: (token) => {
        localStorage.setItem("source", token);
        user.token = token;
        const parsed = parseToken(token);
        user.name = parsed.name;
        user.email = parsed.email;
        user.image = parsed.image;
    },
    loadData: () => {
        const token = localStorage.getItem("source");
        if (token) {
            user.setToken(token);
        }
    },
    logOut: () => {
        localStorage.removeItem("source");
        user.token = '';
        user.name = '';
        user.email = '';
        user.image = '';
        document.location.href = '/login';
    }
}
export const UserContext = createContext(user);

export const UserContextProvider = UserContext.Provider;
export const useUserContext = () => {
    const user = useContext(UserContext);
    user.loadData();
    return user;
}

const parseToken = (token) => {
    const base64Url = token?.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64 || '').split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const parsed = JSON.parse(jsonPayload);
    return {
        name: parsed.name,
        email: parsed.email,
        image: parsed.image,
    }
}