import React from 'react';


const KettleBellIcon = ({
                            className,
                            fontSize
                        }) => {
    return (
        <svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
             viewBox="0 0 24 24">
            <path
                d="m16.2 10.7.6-2.4c.1-.3.5-1.7-.3-2.9-.6-.9-1.8-1.4-3.5-1.4h-2c-1.7 0-2.9.5-3.5 1.4-.8 1.2-.4 2.5-.3 2.9l.6 2.4c-1.1 1.1-1.8 2.6-1.8 4.3 0 2.1 1.1 3.9 2.7 5h6.6c1.6-1.1 2.7-2.9 2.7-5 0-1.7-.7-3.2-1.8-4.3m-6.6-1.2-.5-1.7v-.1s-.2-.7.1-1.1c.2-.4.8-.6 1.8-.6h2c.9 0 1.6.2 1.9.5.3.4.1 1.1.1 1.1l-.5 1.9c-.8-.3-1.6-.5-2.5-.5s-1.7.2-2.4.5z"/>
        </svg>
    )
}
export default KettleBellIcon;