import React from 'react';


const SpinningIcon = ({
                          className,
                          fontSize
                      }) => {
    return (
        <svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
             viewBox="0 0 1280.000000 1084.000000"
             preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1084.000000) scale(0.100000,-0.100000)"
               stroke="none">
                <path d="M4505 10832 c-61 -20 -115 -94 -115 -157 0 -44 36 -107 76 -135 30
-20 48 -24 95 -23 39 1 62 -4 70 -13 8 -10 10 -67 8 -191 -4 -168 -5 -180 -28
-211 -37 -53 -39 -111 -6 -177 l28 -55 -22 -24 c-30 -32 -43 -127 -39 -277 3
-111 5 -122 26 -139 32 -27 31 -97 -4 -150 -23 -34 -26 -48 -22 -93 3 -34 13
-65 27 -85 20 -28 21 -41 19 -139 l-3 -108 -175 -6 c-781 -29 -1573 -263
-2255 -667 -921 -545 -1587 -1356 -1946 -2367 -185 -520 -268 -1123 -229
-1660 60 -825 355 -1622 844 -2285 254 -343 601 -691 940 -940 624 -458 1384
-767 2161 -879 256 -37 377 -45 675 -45 298 0 419 8 675 45 851 122 1641 463
2318 1000 155 122 507 469 628 619 480 591 795 1258 933 1975 206 1075 -18
2179 -637 3138 -545 843 -1410 1497 -2418 1827 -397 131 -785 206 -1211 236
l-98 6 0 107 c0 92 2 108 18 118 27 16 54 80 54 124 -1 40 -21 87 -46 108 -9
8 -16 28 -16 45 l0 31 112 -3 c72 -2 120 -7 133 -16 11 -7 70 -60 131 -117 61
-57 133 -116 160 -131 27 -14 234 -97 459 -183 226 -86 467 -179 536 -206
l127 -50 20 -49 c19 -46 24 -50 54 -50 18 0 53 5 77 11 42 9 47 8 158 -51 395
-209 966 -592 1172 -786 193 -180 619 -706 828 -1019 155 -234 203 -331 328
-663 241 -644 306 -831 371 -1066 30 -110 73 -266 95 -346 78 -282 260 -915
271 -943 10 -26 8 -31 -21 -57 -48 -44 -40 -70 29 -87 34 -9 35 -11 62 -114
16 -57 35 -151 43 -209 l15 -105 -52 -44 c-434 -362 -678 -957 -619 -1506 90
-832 720 -1470 1544 -1566 149 -17 393 -6 537 25 689 147 1209 660 1359 1341
67 306 51 600 -50 910 -196 598 -681 1028 -1319 1167 -92 20 -133 23 -335 23
-202 0 -243 -3 -335 -23 -133 -29 -286 -80 -401 -133 -49 -23 -91 -40 -93 -38
-2 3 -79 251 -167 537 -10 34 -28 91 -39 127 -11 36 -29 94 -40 130 -11 36
-29 95 -41 133 -11 37 -74 252 -139 477 -256 891 -388 1292 -510 1552 -117
251 -421 751 -615 1013 -140 189 -357 419 -650 689 -421 387 -621 537 -853
641 -161 72 -330 150 -434 199 -97 46 -108 62 -108 156 0 64 16 112 40 120 22
7 95 -21 305 -115 349 -156 431 -169 524 -84 35 31 46 50 107 191 25 60 31 65
118 119 173 108 249 178 262 240 16 84 -134 183 -323 213 -74 12 -531 28
-1068 38 l-340 6 -36 28 c-54 42 -84 51 -131 38 -74 -20 -168 -133 -168 -203
0 -14 -6 -26 -13 -26 -7 0 -145 11 -307 25 -236 20 -301 29 -334 45 -46 22
-157 51 -321 84 l-110 23 -3 81 c-3 79 -1 84 28 120 57 72 59 146 5 210 -21
25 -25 41 -25 92 0 57 2 61 16 49 28 -23 124 -18 161 9 131 93 78 290 -80 296
-61 2 -88 15 -107 51 -15 29 -57 55 -88 55 -15 0 -21 9 -27 38 -18 99 -119
163 -210 134z m1355 -1361 c438 -37 471 -41 565 -71 55 -18 141 -53 190 -80
50 -26 115 -57 145 -69 32 -12 90 -51 138 -91 125 -105 147 -115 264 -113 128
2 232 19 290 49 l47 24 7 -36 c11 -57 -3 -126 -31 -153 l-24 -25 -92 13 c-181
26 -261 56 -462 179 -130 78 -176 88 -246 50 -67 -36 -91 -88 -91 -196 0 -63
-3 -83 -12 -79 -7 2 -102 41 -211 86 -109 45 -264 102 -345 127 -81 25 -192
59 -247 76 -150 45 -157 50 -232 152 -37 50 -87 115 -111 144 -35 41 -40 52
-25 52 10 0 228 -18 483 -39z m-1254 -1318 c-4 -274 -11 -777 -16 -1118 -5
-341 -14 -984 -21 -1430 -6 -445 -12 -811 -13 -812 -2 -2 -17 68 -35 155 -31
152 -68 331 -151 735 -21 105 -89 435 -150 732 -133 648 -256 1249 -310 1513
-39 185 -115 560 -126 613 l-5 26 133 21 c218 35 437 56 642 61 l59 1 -7 -497z
m569 461 c128 -16 295 -44 413 -70 58 -13 72 -19 68 -33 -2 -9 -195 -875 -427
-1926 -233 -1050 -424 -1911 -424 -1912 0 -1 -11 1 -24 4 l-24 6 7 406 c3 223
11 719 16 1101 6 382 15 985 20 1340 5 355 10 750 11 879 l0 234 122 -7 c67
-4 176 -14 242 -22z m-1415 -59 c0 -2 29 -147 65 -322 36 -175 84 -405 105
-510 97 -471 121 -589 155 -753 20 -96 90 -438 156 -760 225 -1097 258 -1255
283 -1379 14 -68 26 -127 26 -132 0 -11 -91 -51 -99 -43 -3 3 -353 825 -777
1827 -491 1162 -767 1824 -760 1829 21 12 231 87 356 126 107 34 203 59 415
110 46 11 75 14 75 7z m2069 -69 c215 -59 449 -141 664 -235 l47 -20 -14 -33
c-7 -18 -60 -134 -116 -258 -56 -124 -210 -463 -342 -755 -132 -291 -262 -577
-288 -635 -66 -146 -502 -1107 -650 -1435 -191 -423 -203 -448 -223 -476 l-19
-27 -33 22 c-24 15 -32 27 -29 41 2 11 193 871 424 1910 490 2209 428 1935
441 1935 6 0 68 -15 138 -34z m-2174 -2007 c424 -1002 771 -1829 773 -1839 2
-10 -5 -23 -15 -28 -16 -8 -165 201 -1163 1625 -630 899 -1145 1640 -1145
1645 0 16 281 182 443 264 126 63 320 153 332 153 3 0 352 -819 775 -1820z
m3159 1614 c227 -124 479 -287 474 -307 -3 -13 -2291 -3035 -2296 -3031 -1 1
56 130 127 286 71 156 238 525 371 819 133 294 264 582 290 640 26 58 179 395
340 750 161 355 314 693 341 752 27 60 60 133 73 163 l25 54 77 -35 c42 -20
122 -61 178 -91z m-3570 -1881 c625 -893 1136 -1627 1134 -1630 -8 -18 -54
-82 -58 -82 -4 0 -2669 2577 -2858 2764 l-54 53 39 40 c114 121 373 336 568
472 56 39 60 41 76 24 9 -10 528 -749 1153 -1641z m4271 1413 c143 -118 331
-291 392 -362 l53 -60 -1280 -1251 -1281 -1250 -200 -116 c-110 -64 -205 -116
-210 -116 -5 0 -9 4 -9 9 0 5 -15 31 -33 59 l-34 49 21 40 c12 22 552 740
1201 1596 l1179 1556 51 -36 c27 -20 95 -73 150 -118z m-4660 -1740 c800 -775
1455 -1413 1455 -1417 0 -4 -26 10 -57 31 -32 21 -233 150 -448 288 -214 137
-943 604 -1620 1038 -676 433 -1247 799 -1267 813 l-37 24 41 62 c60 88 142
199 216 291 87 108 245 284 255 282 4 -1 662 -636 1462 -1412z m5298 1091 c78
-98 257 -358 315 -456 l20 -35 -1446 -835 c-795 -459 -1472 -850 -1505 -869
-69 -39 -190 -159 1383 1377 l1055 1031 45 -51 c25 -29 85 -101 133 -162z
m-5646 -1382 c890 -570 1658 -1062 1706 -1093 l88 -56 -7 -50 c-3 -27 -8 -51
-9 -53 -3 -3 -241 40 -385 70 -60 12 -112 35 -1550 688 -531 241 -1161 527
-1400 635 -239 108 -437 199 -439 201 -24 23 336 711 366 700 7 -3 740 -472
1630 -1042z m6106 654 c92 -187 215 -474 205 -483 -3 -3 -307 -131 -1568 -660
-311 -131 -767 -323 -1015 -427 l-450 -190 -332 -47 c-183 -26 -333 -47 -333
-46 0 1 65 66 145 145 l144 143 1538 889 c846 488 1543 888 1549 888 7 0 59
-96 117 -212z m-6523 -1053 c410 -186 943 -428 1185 -537 429 -195 493 -225
450 -214 -11 3 -279 55 -595 116 -316 61 -759 147 -985 190 -225 43 -657 127
-960 185 -733 141 -909 175 -913 179 -5 6 25 154 58 288 32 127 119 400 150
474 l18 42 424 -192 c233 -106 758 -345 1168 -531z m6758 488 c48 -135 102
-344 137 -533 36 -199 39 -220 21 -220 -8 0 -243 -32 -523 -70 -1654 -229
-2603 -360 -2610 -360 -4 0 624 266 1397 590 773 325 1434 603 1470 619 36 16
70 30 76 30 6 1 21 -25 32 -56z m-8184 -668 c215 -42 576 -112 801 -155 226
-44 775 -149 1220 -235 446 -86 888 -171 983 -190 169 -33 178 -36 396 -135
l223 -102 -20 -48 -20 -48 -51 4 c-28 3 -240 13 -471 24 -231 11 -510 25 -620
30 -110 6 -380 19 -600 30 -417 20 -950 47 -1630 80 -220 11 -460 23 -532 27
l-133 6 0 162 c0 151 17 385 40 535 5 36 10 71 10 78 0 6 3 12 6 12 4 0 182
-34 398 -75z m8360 -122 c17 -57 28 -333 24 -564 -3 -143 -7 -261 -9 -264 -3
-2 -708 -9 -1567 -16 l-1562 -13 -355 90 c-195 49 -351 92 -347 96 4 3 133 59
287 124 236 99 294 119 370 130 49 8 646 90 1325 184 679 94 1357 188 1505
209 323 46 322 46 329 24z m-3818 -382 c-101 -101 -126 -121 -158 -126 -46 -8
-45 -9 -50 30 -3 28 3 32 157 122 88 51 162 93 165 93 3 0 -49 -54 -114 -119z
m473 -65 c-2 -2 -120 -52 -262 -111 l-258 -108 -79 18 c-46 10 -80 23 -80 30
0 13 84 95 97 95 5 1 130 18 278 39 271 39 312 44 304 37z m-1537 -102 l146
-27 11 -41 c5 -23 6 -45 2 -49 -7 -7 -336 134 -351 150 -3 3 6 3 20 0 14 -3
91 -18 172 -33z m888 -4 c-11 -11 -22 -20 -24 -20 -2 0 -2 9 1 20 3 11 14 20
24 20 18 0 18 -1 -1 -20z m-3553 -120 c670 -33 1445 -71 1723 -85 278 -14 609
-30 735 -35 127 -6 248 -13 271 -16 l40 -5 0 -56 c-1 -56 -1 -57 -47 -85 -41
-25 -306 -117 -1284 -448 -159 -54 -742 -251 -1294 -439 -552 -188 -1009 -341
-1016 -341 -7 0 -33 49 -59 110 -190 445 -296 882 -332 1368 l-7 92 26 0 c14
0 574 -27 1244 -60z m3636 -11 c-8 -6 -158 -69 -165 -69 -3 0 7 24 21 53 l25
53 63 -15 c35 -8 60 -18 56 -22z m374 -86 c172 -43 313 -80 313 -83 0 -3 -158
-7 -351 -9 l-351 -3 -64 43 c-35 24 -64 46 -64 49 0 8 170 79 190 80 8 0 155
-34 327 -77z m-481 -55 l46 -33 -42 -3 c-23 -2 -43 -2 -45 0 -6 7 -16 68 -11
68 3 0 26 -15 52 -32z m4024 -51 c0 -134 -128 -735 -156 -730 -10 1 -1163 292
-1539 388 -137 35 -493 125 -790 200 -297 75 -542 139 -544 141 -2 2 209 5
470 5 261 1 796 5 1189 8 393 4 862 8 1043 9 l327 2 0 -23z m-1640 -376 c806
-204 1470 -373 1477 -376 23 -8 -120 -389 -232 -618 -45 -91 -62 -116 -74
-112 -24 10 -2053 777 -2446 925 -187 71 -365 138 -395 150 -30 12 -175 105
-323 208 l-268 187 333 6 c183 3 362 5 398 3 47 -1 472 -105 1530 -373z
m-2061 199 c134 -93 246 -171 248 -174 3 -2 -123 44 -278 102 l-282 107 -34
51 c-46 69 -39 84 41 84 l62 0 243 -170z m-352 53 c-11 -10 -37 12 -37 31 1
21 1 21 21 -3 11 -13 18 -26 16 -28z m-698 -15 c11 -24 27 -52 35 -63 13 -19
-56 -82 -1320 -1200 -734 -649 -1353 -1195 -1375 -1214 l-41 -34 -161 164
c-138 140 -358 395 -388 451 -7 14 233 162 1569 966 867 522 1591 954 1607
960 48 17 53 14 74 -30z m700 -12 c18 -10 67 -75 142 -192 63 -98 116 -182
118 -188 2 -5 -76 64 -173 154 -97 90 -176 165 -176 166 0 2 8 19 17 39 18 39
30 42 72 21z m-929 -17 c-93 -63 -3037 -1828 -3045 -1826 -14 4 -133 180 -214
317 -72 121 -185 339 -179 345 3 3 3439 1173 3448 1173 3 1 -2 -4 -10 -9z
m1305 -124 l304 -114 1208 -842 c664 -463 1225 -854 1245 -869 l38 -27 -81
-99 c-79 -96 -442 -464 -458 -464 -4 0 -523 473 -1152 1051 l-1144 1051 -130
201 c-158 244 -148 227 -140 227 3 0 143 -52 310 -115z m-268 -132 l209 -191
817 -1269 c449 -698 819 -1276 822 -1285 7 -19 -32 -43 -240 -147 -180 -89
-447 -203 -456 -193 -3 4 -212 457 -464 1007 -252 550 -543 1186 -647 1413
l-189 412 -39 217 c-44 241 -44 236 -31 231 5 -1 103 -89 218 -195z m-726 171
c15 -14 -59 -118 -1037 -1452 -580 -790 -1058 -1438 -1062 -1440 -10 -3 -229
139 -347 226 -133 98 -317 253 -310 260 19 20 2731 2421 2734 2421 3 1 12 -6
22 -15z m508 -149 c12 -71 24 -143 27 -160 3 -16 -24 35 -61 114 -51 111 -64
146 -53 152 7 4 22 14 33 23 11 8 23 12 26 8 3 -4 16 -65 28 -137z m-438 99
c21 -10 29 -21 26 -32 -3 -9 -131 -291 -285 -627 -154 -335 -479 -1044 -722
-1575 -243 -531 -444 -967 -446 -969 -13 -13 -496 211 -656 305 l-66 39 990
1350 c544 743 1018 1389 1054 1438 35 48 66 87 69 87 3 0 20 -7 36 -16z m405
-200 c81 -175 84 -183 113 -349 16 -93 138 -776 270 -1517 133 -740 241 -1351
241 -1356 0 -15 -270 -54 -513 -73 -193 -14 -627 -7 -807 15 -220 27 -330 48
-330 63 0 8 34 155 75 326 41 172 131 546 200 832 69 286 137 570 151 630 15
61 28 111 29 113 1 1 13 -2 27 -8 20 -10 36 -6 100 23 49 23 85 33 102 30 35
-7 81 13 111 49 13 16 48 39 78 52 83 37 81 30 73 244 -10 242 -18 256 -120
212 -25 -10 -46 -16 -46 -12 1 95 41 876 45 883 4 5 18 9 33 10 15 0 36 4 47
9 11 5 24 7 29 6 6 -1 47 -83 92 -182z m-327 167 c12 -8 13 -40 7 -193 -7
-177 -9 -191 -66 -428 -99 -414 -87 -378 -131 -401 -21 -10 -43 -28 -49 -38
-6 -11 -10 -90 -10 -185 l0 -166 -80 -332 c-44 -183 -139 -576 -210 -873 -72
-297 -143 -593 -158 -657 -15 -66 -32 -118 -38 -118 -24 0 -282 62 -403 96
-147 42 -322 102 -335 114 -5 5 47 129 117 282 128 279 733 1599 1118 2440
118 258 216 468 219 468 3 0 11 -4 19 -9z m2663 -642 c763 -288 1388 -529
1388 -534 0 -15 -136 -245 -210 -355 -103 -153 -181 -255 -192 -252 -11 3
-2377 1649 -2393 1665 -13 13 -163 69 1407 -524z m3923 304 c-5 -52 -44 -653
-76 -1158 -7 -104 -13 -191 -15 -193 -18 -23 -226 176 -304 293 -96 143 -440
849 -440 903 0 11 150 81 248 116 161 57 352 93 509 95 l83 1 -5 -57z m167 42
c62 -8 155 -26 206 -40 88 -24 280 -94 311 -114 12 -8 -11 -68 -123 -328 -76
-175 -190 -438 -254 -585 l-116 -267 -87 -61 -88 -61 -40 28 c-34 24 -40 34
-41 63 0 19 11 204 25 410 14 206 32 485 40 620 20 322 23 350 40 350 8 0 65
-7 127 -15z m-4767 -1085 c578 -530 1060 -973 1073 -985 l24 -22 -79 -64 c-43
-36 -114 -91 -158 -124 -93 -69 -375 -255 -385 -255 -4 0 -340 516 -746 1147
-406 632 -764 1188 -796 1236 -32 49 -58 91 -58 95 0 3 17 -10 38 -30 20 -19
510 -469 1087 -998z m-1526 928 c5 -13 287 -628 626 -1368 339 -740 619 -1350
622 -1356 4 -12 -196 -82 -382 -133 -128 -35 -345 -84 -350 -79 -2 2 -228
1258 -456 2538 -26 151 -55 308 -62 348 -14 73 -13 90 2 50z m6931 -64 c141
-84 287 -205 388 -322 l52 -60 -506 -352 c-279 -193 -508 -350 -509 -348 -1 2
101 239 226 528 126 289 235 542 244 563 8 20 18 37 21 37 3 0 41 -21 84 -46z
m-7406 -194 l-7 -182 -26 -9 c-14 -5 -37 -22 -50 -37 -13 -15 -21 -20 -19 -12
3 8 27 108 53 222 27 114 51 206 53 204 2 -2 0 -86 -4 -186z m5536 48 c0 -6
34 -80 75 -163 100 -205 284 -466 510 -725 119 -136 133 -156 80 -114 -295
231 -929 714 -932 710 -3 -3 -3 -9 0 -13 3 -5 242 -191 531 -413 l525 -405 -6
-42 c-3 -23 -1 -55 5 -72 6 -17 8 -31 4 -31 -13 0 -1377 273 -1381 277 -8 6
31 167 65 266 89 253 245 495 431 666 77 71 93 81 93 59z m2383 -340 c79 -120
152 -271 199 -413 23 -69 40 -126 37 -129 -2 -2 -303 -88 -667 -190 l-664
-186 -55 14 c-30 8 -57 17 -59 19 -3 2 19 59 48 125 l53 121 520 360 c286 199
523 361 527 361 4 0 31 -37 61 -82z m-7763 -22 c0 -19 -4 -38 -10 -41 -6 -4
-9 10 -8 34 2 50 18 55 18 7z m-220 -93 l24 -17 -39 -18 -40 -17 -3 26 c-2 14
2 30 10 34 18 12 20 11 48 -8z m230 -95 c0 -15 -38 -46 -43 -36 -1 2 -11 13
-21 25 -19 23 -19 23 15 37 36 15 49 8 49 -26z m-280 -108 c0 -22 -3 -40 -7
-40 -7 0 -9 34 -4 70 3 27 11 5 11 -30z m8280 -257 c0 -5 7 -48 15 -98 26
-149 20 -403 -11 -556 l-5 -26 -644 161 c-354 89 -644 163 -645 166 0 3 55 20
123 38 67 19 349 98 627 177 278 78 513 143 523 144 9 1 17 -2 17 -6z m-1416
-150 c-14 -32 -33 -75 -41 -95 -8 -21 -21 -38 -28 -38 -18 0 -45 28 -45 45 0
7 -3 20 -6 28 -4 11 17 31 67 66 40 28 74 51 75 51 2 0 -8 -26 -22 -57z
m-1089 -91 c616 -123 695 -141 733 -166 23 -16 42 -35 42 -43 0 -7 -13 -40
-29 -71 -29 -57 -36 -63 -570 -452 -298 -217 -545 -395 -549 -394 -14 1 -123
162 -171 255 -131 250 -193 495 -193 770 0 169 9 239 31 239 9 0 327 -62 706
-138z m1110 -112 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 1 10 9 10 8 0
18 -4 21 -10z m727 -181 c552 -137 658 -167 658 -181 0 -37 -80 -258 -128
-354 -54 -108 -167 -283 -187 -290 -17 -7 -1097 875 -1089 888 4 5 6 27 6 49
-1 35 2 40 31 48 18 5 37 8 42 7 6 -1 306 -76 667 -167z m-1042 27 c0 -7 -8
-20 -17 -27 -15 -12 -16 -12 -10 4 4 10 7 23 7 28 0 5 5 9 10 9 6 0 10 -6 10
-14z m860 -466 c272 -221 498 -406 502 -409 11 -10 -101 -130 -190 -204 -290
-241 -618 -370 -999 -393 l-116 -7 6 94 c102 1432 91 1309 116 1309 9 0 21
-11 27 -24 28 -74 569 -1206 576 -1206 4 0 8 4 8 8 0 4 -130 282 -289 618
l-288 609 36 37 36 37 40 -33 c22 -18 263 -214 535 -436z m-810 414 c8 -7 31
-16 50 -21 32 -9 35 -12 33 -44 -50 -726 -97 -1345 -102 -1350 -9 -9 -156 7
-263 30 -159 33 -394 122 -385 145 3 6 127 277 277 601 241 523 277 594 313
627 40 36 47 37 77 12z m-341 -485 c-105 -228 -225 -486 -266 -574 -41 -88
-78 -164 -83 -169 -12 -12 -60 14 -180 94 -152 102 -347 288 -324 310 21 20
1039 758 1042 756 1 -2 -83 -190 -189 -417z"/>
            </g>
        </svg>
    )
}
export default SpinningIcon;