import React from "react";
import './layout.css';
import {useUserContext} from "../../context/UserContext";
import UserInfo from "../../components/userInfo/UserInfo";

const Menu = () => {
    const user = useUserContext();
    return (
        <div className="menu_container">
            <UserInfo user={user}/>
            <div>
                <span>
                    <div className="pi pi-calendar"/>
                    <a href="/calendar">Calendar</a>
                </span>
              <span>
                  <div className="pi pi-sign-out"/>
                  <a href="/logout">Logout</a>
              </span>
            </div>
        </div>
    )
}

export default Menu;