import axios from 'axios';

export async function get(url) {
    try {
        const axiosResponse = await axios.get(url, getConfig());
        return Promise.resolve({
            success: true,
            data: axiosResponse.data
        });
    } catch (e) {
        return getErrorResponse(e);
    }
}

export async function post(url, body) {
    try {
        const axiosResponse = await axios.post(url, body, getConfig());
        return Promise.resolve({
            success: true,
            data: axiosResponse.data
        });
    } catch (e) {
        return getErrorResponse(e);
    }
}

export async function put(url, body) {
    try {
        const axiosResponse = await axios.put(url, body, getConfig());
        return Promise.resolve({
            success: true,
            data: axiosResponse.data
        });
    } catch (e) {
        return getErrorResponse(e);
    }
}

export async function remove(url) {
    try {
        const axiosResponse = await axios.delete(url, getConfig());
        return Promise.resolve({
            success: true,
            data: axiosResponse.data
        });
    } catch (e) {
        return getErrorResponse(e);
    }
}

const getConfig = () => {
    const value = localStorage.getItem('source');
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${value}`,
        }
    };
}

const getErrorResponse = (e) => {
    const message = e.response?.data?.message;
    const status = e.response?.status;
    return Promise.resolve({
        success: false,
        message,
        status
    });
}