import React from "react";
import WeekContainer from "../../components/weekContainer/weekContainer";

const Calendar = () => {
    return (
        <div className="flex flex-column w-full">
            <WeekContainer/>
        </div>
    )
}

export default Calendar;