const values = {
    HOST: 'https://9cch6c4ro9.execute-api.us-east-1.amazonaws.com/prod',
    AUTH_URL: "{HOST}/authenticate",
    REQUEST_ACCESS_URL: "{HOST}/request-access",
    GET_CLASSES_BY_WEEK_DAY_URL: "{HOST}/classes-by-week-day",
    GET_CLASSES_TO_BOOK_URL: "{HOST}/classes-to-book",
    CREATE_CLASSES_TO_BOOK_URL: "{HOST}/classes-to-book",
    REMOVE_CLASSES_TO_BOOK_URL: "{HOST}/classes-to-book/{id}",
};


export default values;
