import {Outlet} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import React from "react";
import Menu from "./Menu";

const Layout = () => {
    const user = useUserContext();
    if (!user.isAuthenticated()) {
        document.location.href = '/logout';
    }
    const containerStyles = {background: "linear-gradient(to right,#056BAE, #673976)"};
    const outletStyles = {marginBottom: '120px'};
    return (
        <div className="layout_container" style={containerStyles}>
            <div className="layout_menu_container"><Menu/></div>
            <div className="outlet_container" style={outletStyles}>
                <Outlet/>
            </div>
        </div>
    );
}

export default Layout;


