import React from 'react';


const OpenGymIcon = ({
                         className,
                         fontSize
                     }) => {
    return (<svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
                 viewBox="0 0 256 256">
            <g>
                <g>
                    <g>
                        <path
                              d="M180.5,16l-6,6l29.8,29.8L234,81.5l6-6l6-6l-29.8-29.8L186.5,10L180.5,16z"/>
                        <path
                              d="M147,19.4l-8.3,8.3l44.9,44.8l44.8,44.9l8.3-8.3c4.6-4.6,8.3-8.5,8.3-8.8c0-0.7-88.5-89.2-89.2-89.2C155.5,11,151.5,14.8,147,19.4z"/>
                        <path
                              d="M126.2,40.2l-8.3,8.3l17.8,17.8l17.8,17.8l-34.7,34.7l-34.7,34.7l-17.8-17.8l-17.8-17.8l-8.3,8.3c-4.6,4.6-8.3,8.5-8.3,8.8c0,0.7,88.5,89.2,89.2,89.2c0.3,0,4.3-3.7,8.8-8.3l8.3-8.3l-17.8-17.8l-17.8-17.8l34.7-34.7l34.7-34.7l17.8,17.8l17.8,17.8l8.3-8.3c4.6-4.6,8.3-8.5,8.3-8.8c0-0.7-88.5-89.2-89.2-89.2C134.7,31.8,130.8,35.6,126.2,40.2z"/>
                        <path
                              d="M19.2,147.1c-4.5,4.5-8.2,8.4-8.2,8.7c0,0.7,88.5,89.2,89.2,89.2c0.3,0,4.3-3.7,8.8-8.3l8.3-8.3l-44.7-44.7c-24.6-24.6-44.8-44.7-45-44.7C27.5,138.9,23.7,142.6,19.2,147.1z"/>
                        <path
                              d="M15.8,180.7l-5.8,5.8l29.8,29.8L69.5,246l6-6l6-6l-29.6-29.6c-16.3-16.3-29.7-29.6-29.9-29.6C21.8,174.8,19,177.4,15.8,180.7z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default OpenGymIcon;