import "/node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import 'primeicons/primeicons.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/layout/Layout";
import Home from "./pages/home/Home";
import SignIn from "./pages/signIn/SignIn";
import {PrimeReactProvider} from 'primereact/api';
import {user, UserContextProvider} from "./context/UserContext";
import {useEffect, useState} from "react";
import Profile from "./pages/profile/Profile";
import SignOut from "./pages/signOut/SignOut";
import Calendar from "./pages/calendar/Calendar";
import RequestAccess from "./pages/requestAccess/RequestAccess";

function App() {
    const [currentUser] = useState(user);
    const value = {
        cssTransition: true,
        appendTo: 'self',
    };

    useEffect(() => {
        currentUser.loadData();
    }, [currentUser]);
    return (
        <PrimeReactProvider value={value}>
            <UserContextProvider value={currentUser}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/login' element={<SignIn/>}/>
                        <Route path='/logout' element={<SignOut/>}/>
                        <Route path='/access' element={<RequestAccess/>}/>
                        <Route path='/' element={<Layout/>}>
                            <Route path='/home' element={<Home/>}/>
                            <Route path='/profile' element={<Profile/>}/>
                            <Route path='/calendar' element={<Calendar/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </UserContextProvider>
        </PrimeReactProvider>
    );
}

export default App;
