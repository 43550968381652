import React from 'react';


const RingsIcon = ({
                       className,
                       fontSize
                   }) => {
    return (
        <svg style={{fontSize: fontSize}} className={className} width="1em" height="1em" x="0px" y="0px"
             viewBox="0 0 512 512">
            <g>
                <path d="M366.78,194.748c-0.623-10.436-7.467-19.14-16.913-22.511V8.533c0-4.719-3.814-8.533-8.533-8.533H307.2
			c-4.719,0-8.533,3.814-8.533,8.533v163.703c-9.446,3.371-16.29,12.075-16.913,22.511c-21.743,7.159-41.506,19.831-57.216,36.617
			c-2.927-3.584-6.758-6.34-11.204-7.927V8.533c0-4.719-3.814-8.533-8.533-8.533h-34.133c-4.719,0-8.533,3.814-8.533,8.533v214.903
			c-9.446,3.371-16.29,12.075-16.913,22.511C89.583,264.201,51.2,316.501,51.2,375.467C51.2,450.748,112.452,512,187.733,512
			c44.365,0,83.738-21.367,108.698-54.255c9.404,1.988,18.739,3.055,27.836,3.055c75.281,0,136.533-61.252,136.533-136.533
			C460.8,265.301,422.417,213.001,366.78,194.748z M298.667,201.011v-4.745c0-4.71,3.823-8.533,8.533-8.533h34.133
			c4.71,0,8.533,3.823,8.533,8.533v4.745V230.4c0,4.71-3.823,8.533-8.533,8.533H307.2c-4.71,0-8.533-3.823-8.533-8.533V201.011z
			 M162.133,252.211v-4.745c0-4.71,3.823-8.533,8.533-8.533H204.8c4.71,0,8.533,3.823,8.533,8.533v0.179v4.565V281.6
			c0,1.092-0.247,2.116-0.623,3.072c-0.154,0.393-0.401,0.717-0.606,1.075c-0.299,0.521-0.606,1.041-0.998,1.485
			c-0.247,0.282-0.538,0.521-0.819,0.768c-0.503,0.435-1.058,0.811-1.656,1.118c-0.282,0.145-0.546,0.299-0.836,0.41
			c-0.939,0.358-1.937,0.606-2.995,0.606h-5.862h-28.271c-4.71,0-8.533-3.823-8.533-8.533V252.211z M187.733,460.8
			c-47.053,0-85.333-38.281-85.333-85.333c0-32.947,19.465-63.113,49.067-77.107c4.702,5.376,11.52,8.841,19.2,8.841h18.33
			c-0.751,5.768-1.263,11.494-1.263,17.067c0,44.928,22.502,87.074,59.315,112.418C231.68,451.576,210.773,460.8,187.733,460.8z
			 M271.556,391.168c-20.352-16.085-32.623-40.798-32.623-66.901c0-5.282,0.555-10.522,1.527-15.684
			c20.139,15.94,32.606,40.525,32.606,66.884C273.067,380.834,272.512,386.065,271.556,391.168z M324.267,409.6
			c-1.434,0-2.944-0.12-4.437-0.205c2.79-10.871,4.437-22.195,4.437-33.929c0-45.662-22.997-87.381-59.213-112.358
			c6.767-6.528,14.575-11.819,23.057-15.821C292.804,252.587,299.58,256,307.2,256h34.133c7.68,0,14.498-3.465,19.2-8.841
			c29.602,13.995,49.067,44.16,49.067,77.107C409.6,371.319,371.319,409.6,324.267,409.6z"/>
            </g>
        </svg>
    )
}
export default RingsIcon;