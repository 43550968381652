import {useState} from "react";
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Message} from 'primereact/message';
import {useUserContext} from "../../context/UserContext";
import {authenticate} from "../../service/authService";


const SignIn = () => {
    const user = useUserContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    if (user.isAuthenticated()) {
        document.location.href = '/calendar';
    }
    const logIn = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await authenticate(email, password);
            if (!response.success) {
                setError(response.message);
            } else {
                user.setToken(response.data);
                document.location.href = '/calendar';
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(`Invalid Credentials. You can request access by clicking the "Request Access" link below.`);
        }
    }
    return (
        <div className="h-screen flex-wrap flex flex-column justify-content-center align-content-center">
            <div className="p-fluid w-24rem">
                <div className="text-4xl text-primary">CrossFit Class Scheduler</div>
                <Message className="mt-3 text-4xl" severity="info"
                         text="You must use the same credentials you use in Zen Planner app"/>
                <div className="mt-3 surface-700 border-round-lg border-1 p-5">
                    <InputText className="p-inputtext-lg"
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               placeholder="Email"
                    />
                    <Password className="mt-3 p-inputtext-lg" value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Password"
                              toggleMask/>
                    <Button size="large" className="mt-3" label="Let me in" icon="pi pi-check" iconPos="right"
                            onClick={logIn} loading={loading}/>
                </div>
                {error && <Message className="mt-3 p-fluid" severity="error" text={error}/>}
                <a className="mt-3" href="/access" >Request Access</a>
            </div>
        </div>
    )
}

export default SignIn;