import {useUserContext} from "../../context/UserContext";


const SignOut = () => {
    const user = useUserContext();
    user.logOut();
    return (
        <></>
    )
}

export default SignOut;