export function get(name, data) {
    const config = getConfig();
    return replaceValues(name, config, data);
}

const getConfig = () => {
    const env = process.env.REACT_APP_ENVIRONMENT || "development";
    const envConfig = loadConfig(env);
    const defaultConfig = loadConfig("default");
    return Object.assign({...defaultConfig, ...envConfig});
};

const replaceValues = (name, config, data) => {
    const HOST = config["HOST"] || "";
    let value = config[name] || "";
    if (data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                value = value.replace(`{${key}}`, data[key]);
            }
        }
    }
    value = value.replace("{HOST}", HOST);
    return removeUnsetParams(value);
};

const removeUnsetParams = (data) => {
    return data
        .replace(/(\??[a-zA-Z0-9]+=({[A-Z]+}|(null)|(undefined))&?)/g, "")
        .replace(/[&=]+$/g, "");
};

const loadConfig = (name) => {
    try {
        // eslint-disable-next-line
        return require(`../config/${name}.config`).default;
    } catch (e) {
        return {};
    }
};
