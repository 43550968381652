import {get} from "../util/config";
import {post} from "../util/restClient";

export async function authenticate(email, password) {
    const url = get('AUTH_URL');
    return await post(url, {email, password});
}

export async function requestAccess(email, password) {
    const url = get('REQUEST_ACCESS_URL');
    return await post(url, {email, password});
}