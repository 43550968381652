import React from 'react';
import './DisplayClass.css';
import KettleBellIcon from "../icon/kettlebell";
import RingsIcon from "../icon/rings";
import WeightLiftingIcon from "../icon/weightLifting";
import TeamIcon from "../icon/team";
import OpenGymIcon from "../icon/open";
import UnknownIcon from "../icon/unknown";
import {ProgressSpinner} from "primereact/progressspinner";
import SpinningIcon from "../icon/spinning";

const DisplayClass = ({element, onElementClick, onHoverText, loading}) => {

    const getIcon = () => {
        console.log(element.name)
        switch (element.name) {
            case "WOD":
                return <KettleBellIcon fontSize={40}/>;
            case "TEAM WOD":
                return <TeamIcon fontSize={40}/>;
            case "GYMNASTIQUE":
                return <RingsIcon fontSize={40}/>;
            case "Open Gym":
                return <OpenGymIcon fontSize={40}/>;
            case "Haltérophilie/ Weight Lifting":
                return <WeightLiftingIcon fontSize={35}/>;
            case "Open-Gym Haltérophilie/ Power Lifting":
                return <WeightLiftingIcon fontSize={35}/>;
            case "Spinning":
                return <SpinningIcon fontSize={35}/>;
            default:
                return <UnknownIcon fontSize={35}/>;
        }
    }

    const getColorClass = () => {
        switch (element.name) {
            case "WOD":
                return "wod";
            case "TEAM WOD":
                return "team_wod";
            case "GYMNASTIQUE":
                return "gymnastics";
            case "Open Gym":
                return "open_gym";
            case "Haltérophilie/ Weight Lifting":
                return "weight_lifting";
            case "Spinning":
                return "spinning";
            default:
                return "unknown_class";
        }
    }
    const onClick = () => {
        onElementClick && onElementClick(element);
    }

    return (
        <div className={`class_container ${getColorClass()} animate-width animation-duration-500`} onClick={onClick}>
            {loading && <div className="loading_container">
                <ProgressSpinner strokeWidth="8" animationDuration=".5s"/>
            </div>}
            {onHoverText && <div className="hover_container">{onHoverText}</div>}
            <div className="class_icon">{getIcon()}</div>
            <div className="class_info">
                <div className="class_name">{element.name}</div>
                <div className="class_time">{element.time}</div>
            </div>
        </div>
    )
}

export default DisplayClass