import React from 'react';
import './userInfo.css';

const UserInfo = ({user}) => {

    return (
        <div className="profile_info">
            <img src={user.image} alt="profile"/>
            <div>
                <div>{user.name}</div>
                <div>{user.email}</div>
            </div>
        </div>
    )
}

export default UserInfo